import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import { linkResolver } from '../../util/linkResolver'

const LinkWrapper = ({ link, children }) => {
  const [isValidLink, setIsValidLink] = useState(null)
  const [isBlankTarget, setIsBlankTarget] = useState(false)

  useEffect(() => {
    setIsValidLink(link?.url || link?.uid || link?.type || link?.id)
    setIsBlankTarget(link?.target === '_blank')
  }, [link])

  if (isValidLink && isBlankTarget) {
    return (
      <a
        href={linkResolver(link)}
        target={link.target}
        rel="noopener noreferrer"
      >
        {children}
      </a>
    )
  }

  return isValidLink ? (
    <Link to={linkResolver(link)}>{children}</Link>
  ) : (
    <>{children}</>
  )
}

export default LinkWrapper
