import styled from 'styled-components'
import { DEVICE } from '../../util/constants'
import { HeroH1, HeroP } from './Utility.styles'
import { HeroGraphicDisease } from '../Icons'

export const HeroRegion = styled.div`
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${DEVICE.tabletL} {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const HeroImage = styled.img`
  @media ${DEVICE.tabletL} {
    margin-top: 50px;
    width: 317px;
  }
`

export const TextColumn = styled.div`
  @media ${DEVICE.tabletL} {
    flex: 0 1 70%;
    padding-right: 20px;
  }
`

export const ImageColumn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media ${DEVICE.tabletL} {
    flex-direction: column;
    justify-content: flex-start;
    flex: 0 0 30%;
  }
`

export const HeroTitle = styled(HeroH1)`
  padding: 40px 0 14px;

  @media ${DEVICE.tabletL} {
    padding: 50px 0 20px;
  }
`

export const HeroBody = styled(HeroP)`
  p {
    margin-bottom: 2rem;
    padding-right: 32px;
  }
`

export const FilterLabelSelectPair = styled.div`
  display: flex;
  flex-direction: column;
`

export const FilterLabel = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.71;
  letter-spacing: 0.02em;
  color: var(--content-background-dark-blue);
`

export const ProductDirectorySectionTitle = styled.h2`
  grid-column: 2 / 3;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 2.8rem;
  line-height: 1.35;
  color: var(--highlight-blue);
  padding-top: 38px;

  @media ${DEVICE.tabletL} {
    font-size: 4.8rem;
  }
`

export const ItemsGridContainer = styled.div`
  grid-column: 2 / 3;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  column-gap: 32px;

  @media screen and (min-width: 870px) {
    grid-template-columns: repeat(auto-fill, 400px);
  }

  @media screen and (min-width: 1350px) {
    grid-template-columns: repeat(auto-fill, 311px);
  }
`

export const GreyFill = styled.div`
  margin-top: 32px;
  padding-top: 51px;
  width: 100%;
  height: max-content;
  background-color: var(--content-background-light);
`
