import styled from 'styled-components'

import { DEVICE, BLOCK_MARGIN_TOP } from '../../../util/constants'
import { GriddedContentBlock } from '../../styles/Utility.styles'

export const GridContainer = styled(GriddedContentBlock)`
  padding-bottom: 0;
  @media ${DEVICE.tabletL} {
    margin-top: ${BLOCK_MARGIN_TOP}px;
    padding-bottom: ${BLOCK_MARGIN_TOP}px;
  }
`

export const AdvanceArticleHero = styled.div`
  grid-column: 2 / 12;
  display: grid;
  grid-template-columns: 1fr 186px 12px;
  grid-template-rows: max-content;

  @media ${DEVICE.tabletL} {
    grid-template-columns: 7fr 150px 150px;
  }
`

export const HeroGraphicContainer = styled.div`
  grid-column: 2 / 3;
  grid-row: 1;
  width: 100%;

  @media ${DEVICE.tabletL} {
    grid-column: 2 / 4;
    padding-top: unset;
  }
`

export const TextColumn = styled.div`
  grid-row: 1;
  grid-column: 1 / 4;

  @media ${DEVICE.tabletL} {
    grid-row: 1;
    grid-column: 1 /3;
  }
`

export const PageTitle = styled.span`
  display: ${({ isMobile }) => (isMobile ? 'block' : 'none')};
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.7;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--content-background-dark-blue);

  @media ${DEVICE.tabletL} {
    display: ${({ isMobile }) => (isMobile ? 'none' : 'block')};
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 37px;
  }
`

export const HeroTextRegion = styled.div`
  display: flex;
  flex-direction: column;
  max-height: max-content;
  border-radius: 5px;
  margin-top: 60px;

  @media ${DEVICE.tabletL} {
    flex-direction: row;
    margin-top: 37px;
    min-height: calc(100% - 2rem * 1.2 - 37px);
  }
`

export const CenteredTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 14px 0 30px;
  width: 100%;

  @media ${DEVICE.tabletL} {
    padding: ${({ hasImage }) => (hasImage ? '0 30px 0 0' : '0 275px 0 0')};
  }
`

export const HeroTitle = styled.div`
  h1 {
    font-weight: 300;
    font-size: 2.8rem;
    line-height: 1.35;
    padding-bottom: 5px;
    margin-bottom: 0;
    color: var(--highlight-blue);

    @media ${DEVICE.tabletL} {
      padding-bottom: 10px;
      font-size: 4.8rem;
    }
  }
`

export const HeroText = styled.div`
  font-size: 1.4rem;
  line-height: 1.6;
  padding-top: 5px;
  margin-top: 0;
  color: var(--content-background-dark-blue);

  @media ${DEVICE.tabletL} {
    padding-top: 10px;
    font-size: 2rem;
  }
`

export const HeroImage = styled.img`
  width: 100%;
  @media ${DEVICE.tabletL} {
    height: 300px;
    width: 367px;
    min-height: 300px;
    min-width: 367px;
  }
`

export const BackButtonContainer = styled.div`
  grid-column: 2 / 12;
  margin-bottom: 30px;

  @media ${DEVICE.tabletL} {
    margin-bottom: 20px;
  }
`

export const BackButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration-line: none;
  outline: none;
  background: 0;

  svg {
    margin-right: 20px;
  }

  span {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.357;
    text-transform: uppercase;
    color: var(--content-background-dark-blue);
  }

  @media ${DEVICE.tabletL} {
    span {
      font-size: 1.4rem;
    }
  }
`
